import createDataContext from "./createDataContext";
import { API, graphqlOperation } from "aws-amplify";
import {
  getUserControllers as fetchControllers,
  getAllUserTimers,
} from "../graphql/queries";
import { createTimer as graphqlCreateTimer } from "../graphql/mutations";

const timerReducer = (state, action) => {
  switch (action.type) {
    case "set_user_controllers":
      return { ...state, controllers: action.payload };
    case "fetch_timers":
      return { ...state, savedTimers: action.payload };
    default:
      return state;
  }
};

const fetchTimers = (dispatch) => async () => {
  try {
    const timers = await API.graphql(graphqlOperation(getAllUserTimers));
    dispatch({
      type: "fetch_timers",
      payload: timers.data.getAllUserTimers.timers,
    });
  } catch (error) {
    console.log("Failed to fetch timers");
  }
};

const getUserControllers = (dispatch) => async () => {
  try {
    const userControllers = await API.graphql(
      graphqlOperation(fetchControllers)
    );
    dispatch({
      type: "set_user_controllers",
      payload: userControllers.data.getUserControllers.items.map(
        (controller) => controller["controller_serial"]
      ),
    });
  } catch (error) {
    console.log(`Error Fetching Controllers: ${error}`);
  }
};

export const { Provider, Context } = createDataContext(
  timerReducer,
  {
    getUserControllers,
    fetchTimers,
  },
  {
    startTime: "",
    endTime: "",
    repeatDays: "",
    patternForTimer: "",
    controllers: [],
    timerErrorMessage: null,
    timerSuccessMessage: null,
    savedTimers: [],
  }
);
