import React, { useState, useEffect, useContext } from "react";

import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import Popper from "@material-ui/core/Popper";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Divider from "@material-ui/core/Divider";
import { Context as LightingContext } from "../../context/LightingContext";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import DeleteIcon from "@material-ui/icons/Delete";
import LinearProgress from "@material-ui/core/LinearProgress";
import { API, graphqlOperation } from "aws-amplify";
import { deletePatterns } from "../../graphql/mutations";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    minWidth: 360,
    backgroundColor: "#282c34",
  },
  nested: {
    padding: theme.spacing(4),
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
    backgroundColor: "#90CAF9",
    color: "#282c34",
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    alignItems: "center",
  },
  fixedHeight: {
    height: 340,
  },
  listItem: {
    backgroundColor: "#282c34",
  },
  listSubheader: {
    color: "#FFFFFF",
    background: "#666666",
    fontWeight: "Bold",
  },
  listItemText: {
    color: "#ffffff",
  },
  divider: {
    backgroundColor: "#666666",
    marginLeft: 17,
  },
  linearProgressContainer: {
    backgroundColor: "#444444",
    height: 3,
  },
}));

const ExpandableList = () => {
  const [savedPatternsOpen, setSavedPatternsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const anchorRef = React.useRef(null);
  const {
    setAllLightingOptions,
    getPatterns,
    setErrorMessage,
    setSuccessMessage,
    state,
  } = useContext(LightingContext);
  const { savedPatterns } = state;

  useEffect(() => {
    setLoading(false);
  }, [savedPatterns]);

  const handleToggle = () => {
    setSavedPatternsOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setSavedPatternsOpen(false);
  };

  const deletePattern = async ({ id }) => {
    setLoading(true);
    try {
      await API.graphql(
        graphqlOperation(deletePatterns, {
          input: {
            id,
          },
        })
      );
      getPatterns();
    } catch (error) {
      setLoading(false);
      if (
        error.errors[0]?.message ===
        "Pattern is used for a Timer, cannot delete!"
      ) {
        setErrorMessage({
          message: "Cannot Delete a Pattern Being Used by a Timer!",
        });
        return;
      }
      setErrorMessage({
        message: "Failed to Delete Pattern",
      });
    }
  };

  const onPatternClicked = (pattern) => {
    setAllLightingOptions({
      lightingMode: pattern.type,
      numberOfLights: pattern.colors.length,
      speedOfPattern: pattern.speed,
      gapBetweenPattern: pattern.gap,
      patternPause: pattern.pause,
      patternDirection: pattern.direction,
      mqttTopic: null,
      ledData: pattern.colors.map((color, index) => {
        if (index === 0) {
          return { color: `#${color}`, selected: true };
        } else {
          return { color: `#${color}`, selected: false };
        }
      }),
    });
  };

  const prevOpen = React.useRef(savedPatternsOpen);
  useEffect(() => {
    if (prevOpen.current === true && savedPatternsOpen === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = savedPatternsOpen;
  }, [savedPatternsOpen]);

  useEffect(() => {
    if (savedPatternsOpen) {
      setLoading(true);
      getPatterns();
    }
  }, [savedPatternsOpen]);

  return (
    <div>
      <IconButton
        color="inherit"
        ref={anchorRef}
        aria-controls={savedPatternsOpen ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Button color="inherit">Saved Patterns</Button>
      </IconButton>
      <Popper
        open={savedPatternsOpen}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <div className={classes.root}>
                  <div className={classes.linearProgressContainer}>
                    {loading && <LinearProgress />}
                  </div>
                  <List
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    subheader={
                      <ListSubheader
                        className={classes.listSubheader}
                        component="div"
                        id="nested-list-subheader"
                      >
                        Saved Patterns
                      </ListSubheader>
                    }
                    className={classes.root}
                  >
                    {savedPatterns.map((pattern) => (
                      <>
                        <ListItem
                          button
                          onClick={() => onPatternClicked(pattern)}
                        >
                          <ListItemText
                            className={classes.listItemText}
                            primary={pattern.name}
                          />
                          <ListItemSecondaryAction>
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              style={{ color: "#ffffff" }}
                              onClick={() => {
                                deletePattern({ id: pattern.id });
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                        <Divider className={classes.divider} />
                      </>
                    ))}
                  </List>
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default ExpandableList;
