import createDataContext from "./createDataContext";
import { API, graphqlOperation } from "aws-amplify";
import { queryPatternsByUsernameIndex } from "../graphql/queries";

const lightingReducer = (state, action) => {
  switch (action.type) {
    case "set_lighting_mode":
      return { ...state, lightingMode: action.payload };
    case "set_number_of_lights":
      return { ...state, numberOfLights: action.payload };
    case "set_speed_of_pattern":
      return { ...state, speedOfPattern: action.payload };
    case "set_gap_between_pattern":
      return { ...state, gapBetweenPattern: action.payload };
    case "set_pattern_pause":
      return { ...state, patternPause: action.payload };
    case "set_pattern_direction":
      return { ...state, patternDirection: action.payload };
    case "set_mqtt_topic":
      return { ...state, mqttTopic: action.payload };
    case "set_led_data":
      return { ...state, ledData: action.payload };
    case "set_all_lighting_options":
      return {
        ...state,
        lightingMode: action.payload.lightingMode,
        numberOfLights: action.payload.numberOfLights,
        speedOfPattern: action.payload.speedOfPattern,
        gapBetweenPattern: action.payload.gapBetweenPattern,
        patternPause: action.payload.patternPause,
        patternDirection: action.payload.patternDirection,
        ledData: action.payload.ledData,
      };
    case "get_patterns":
      return {
        ...state,
        savedPatterns: action.payload,
        errorMessage: null,
      };
    case "get_patterns_error":
      return { ...state, errorMessage: action.payload };
    case "set_error_message":
      return { ...state, errorMessage: action.payload };
    case "set_success_message":
      return { ...state, successMessage: action.payload };
    default:
      return state;
  }
};

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const setLightingMode = (dispatch) => ({ lightingMode }) => {
  dispatch({
    type: "set_lighting_mode",
    payload: lightingMode,
  });
};

const setNumberOfLights = (dispatch) => ({ numberOfLights }) => {
  dispatch({
    type: "set_number_of_lights",
    payload: numberOfLights,
  });
};

const setSpeedOfPattern = (dispatch) => ({ speedOfPattern }) => {
  dispatch({
    type: "set_speed_of_pattern",
    payload: speedOfPattern,
  });
};

const setGapBetweenPattern = (dispatch) => ({ gapBetweenPattern }) => {
  dispatch({
    type: "set_gap_between_pattern",
    payload: gapBetweenPattern,
  });
};

const setPatternPause = (dispatch) => ({ patternPause }) => {
  dispatch({
    type: "set_pattern_pause",
    payload: patternPause,
  });
};

const setPatternDirection = (dispatch) => ({ patternDirection }) => {
  dispatch({
    type: "set_pattern_direction",
    payload: patternDirection,
  });
};

const setMqttTopic = (dispatch) => ({ mqttTopic }) => {
  dispatch({
    type: "set_mqtt_topic",
    payload: mqttTopic,
  });
};

const setLedData = (dispatch) => ({ ledData }) => {
  dispatch({
    type: "set_led_data",
    payload: ledData,
  });
};

const getPatterns = (dispatch) => async () => {
  try {
    const patterns = await API.graphql(
      graphqlOperation(queryPatternsByUsernameIndex)
    );
    dispatch({
      type: "get_patterns",
      payload: patterns.data.queryPatternsByUsernameIndex.items,
    });
  } catch (error) {
    dispatch({
      type: "get_patterns_error",
      payload: error,
    });
  }
};

const setErrorMessage = (dispatch) => async ({ message }) => {
  dispatch({
    type: "set_error_message",
    payload: message,
  });
  await sleep(4000);
  dispatch({
    type: "set_error_message",
    payload: null,
  });
};

const setSuccessMessage = (dispatch) => async ({ message }) => {
  dispatch({
    type: "set_success_message",
    payload: message,
  });
  await sleep(4000);
  dispatch({
    type: "set_success_message",
    payload: null,
  });
};

const setAllLightingOptions = (dispatch) => ({
  lightingMode,
  numberOfLights,
  speedOfPattern,
  gapBetweenPattern,
  patternPause,
  patternDirection,
  ledData,
}) => {
  dispatch({
    type: "set_all_lighting_options",
    payload: {
      lightingMode,
      numberOfLights,
      speedOfPattern,
      gapBetweenPattern,
      patternPause,
      patternDirection,
      ledData,
    },
  });
};

export const { Provider, Context } = createDataContext(
  lightingReducer,
  {
    setLightingMode,
    setNumberOfLights,
    setSpeedOfPattern,
    setGapBetweenPattern,
    setPatternPause,
    setPatternDirection,
    setMqttTopic,
    setLedData,
    setAllLightingOptions,
    getPatterns,
    setErrorMessage,
    setSuccessMessage,
  },
  {
    lightingMode: "Custom",
    numberOfLights: 1,
    speedOfPattern: 1,
    gapBetweenPattern: 0,
    patternPause: 0,
    patternDirection: "Forward",
    mqttTopic: "",
    ledData: [{ color: "#ffffff", selected: true }],
    savedPatterns: [],
    errorMessage: null,
    successMessage: null,
  }
);
