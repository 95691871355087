/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createControllerMqttTopics = /* GraphQL */ `
  mutation CreateControllerMqttTopics(
    $input: CreateControllerMqttTopicsInput!
  ) {
    createControllerMqttTopics(input: $input) {
      mqtt_topic
      serial
    }
  }
`;
export const createControllers = /* GraphQL */ `
  mutation CreateControllers($input: CreateControllersInput!) {
    createControllers(input: $input) {
      light_count
      main_topic
      nickname
      rgb_order
      serial
      version
    }
  }
`;
export const createPatterns = /* GraphQL */ `
  mutation CreatePatterns($input: CreatePatternsInput!) {
    createPatterns(input: $input) {
      colors
      direction
      gap
      id
      name
      pause
      speed
      type
      username
    }
  }
`;
export const createTimer = /* GraphQL */ `
  mutation CreateTimer($input: CreateTimerInput!) {
    createTimer(input: $input) {
      active
      end_time
      id
      mqtt_topic
      name
      pattern {
        colors
        direction
        gap
        id
        name
        pause
        speed
        type
        username
      }
      repeats
      start_time
    }
  }
`;
export const createCalendar = /* GraphQL */ `
  mutation CreateCalendar($input: CreateCalendarInput!) {
    createCalendar(input: $input) {
      active
      end_time
      id
      mqtt_topic
      name
      pattern {
        colors
        direction
        gap
        id
        name
        pause
        speed
        type
        username
      }
      day
      month
      start_time
    }
  }
`;
export const createUserControllers = /* GraphQL */ `
  mutation CreateUserControllers($input: CreateUserControllersInput!) {
    createUserControllers(input: $input) {
      controller_serial
      username
    }
  }
`;
export const createUserMqttTopics = /* GraphQL */ `
  mutation CreateUserMqttTopics($input: CreateUserMqttTopicsInput!) {
    createUserMqttTopics(input: $input)
  }
`;
export const deleteControllerMqttTopics = /* GraphQL */ `
  mutation DeleteControllerMqttTopics(
    $input: DeleteControllerMqttTopicsInput!
  ) {
    deleteControllerMqttTopics(input: $input) {
      mqtt_topic
      serial
    }
  }
`;
export const deleteControllers = /* GraphQL */ `
  mutation DeleteControllers($input: DeleteControllersInput!) {
    deleteControllers(input: $input) {
      light_count
      main_topic
      nickname
      rgb_order
      serial
      version
    }
  }
`;
export const deletePatterns = /* GraphQL */ `
  mutation DeletePatterns($input: DeletePatternsInput!) {
    deletePatterns(input: $input)
  }
`;
export const deleteTimer = /* GraphQL */ `
  mutation DeleteTimer($id: String!) {
    deleteTimer(id: $id)
  }
`;
export const deleteCalendar = /* GraphQL */ `
  mutation DeleteCalendar($id: String!) {
    deleteCalendar(id: $id)
  }
`;
export const deleteUserControllers = /* GraphQL */ `
  mutation DeleteUserControllers($input: DeleteUserControllersInput!) {
    deleteUserControllers(input: $input) {
      controller_serial
      username
    }
  }
`;
export const deleteUserMqttTopics = /* GraphQL */ `
  mutation DeleteUserMqttTopics($mqtt_topic: String!) {
    deleteUserMqttTopics(mqtt_topic: $mqtt_topic)
  }
`;
export const deleteUserMqttTopicsList = /* GraphQL */ `
  mutation DeleteUserMqttTopicsList($mqtt_topic_list: [String!]) {
    deleteUserMqttTopicsList(mqtt_topic_list: $mqtt_topic_list)
  }
`;
export const lightsOff = /* GraphQL */ `
  mutation LightsOff($mqtt_topic: String!) {
    lightsOff(mqtt_topic: $mqtt_topic)
  }
`;
export const setPattern = /* GraphQL */ `
  mutation SetPattern($mqtt_topic: String!, $pattern: SetPatternInput!) {
    setPattern(mqtt_topic: $mqtt_topic, pattern: $pattern)
  }
`;
export const setTimerActive = /* GraphQL */ `
  mutation SetTimerActive($active: Int!, $id: String!) {
    setTimerActive(active: $active, id: $id)
  }
`;
export const setCalendarActive = /* GraphQL */ `
  mutation SetCalendarActive($active: Int!, $id: String!) {
    setCalendarActive(active: $active, id: $id)
  }
`;
export const setTimerActiveController = /* GraphQL */ `
  mutation SetTimerActiveController(
    $active: Int!
    $id: String!
    $serial: String!
  ) {
    setTimerActiveController(active: $active, id: $id, serial: $serial)
  }
`;
export const updateControllerMqttTopics = /* GraphQL */ `
  mutation UpdateControllerMqttTopics(
    $input: UpdateControllerMqttTopicsInput!
  ) {
    updateControllerMqttTopics(input: $input) {
      mqtt_topic
      serial
    }
  }
`;
export const updateControllers = /* GraphQL */ `
  mutation UpdateControllers($input: UpdateControllersInput!) {
    updateControllers(input: $input) {
      light_count
      main_topic
      nickname
      rgb_order
      serial
      version
    }
  }
`;
export const updatePatterns = /* GraphQL */ `
  mutation UpdatePatterns($input: UpdatePatternsInput!) {
    updatePatterns(input: $input)
  }
`;
export const updateUserControllers = /* GraphQL */ `
  mutation UpdateUserControllers($input: UpdateUserControllersInput!) {
    updateUserControllers(input: $input) {
      controller_serial
      username
    }
  }
`;
export const updateUserMqttTopics = /* GraphQL */ `
  mutation UpdateUserMqttTopics($input: UpdateUserMqttTopicsInput!) {
    updateUserMqttTopics(input: $input) {
      mqtt_topic
      username
    }
  }
`;
export const registerController = /* GraphQL */ `
  mutation RegisterController($serial: String!, $signup_key: String!) {
    registerController(serial: $serial, signup_key: $signup_key)
  }
`;
export const inviteUserToControllers = /* GraphQL */ `
  mutation InviteUserToControllers($email: String!, $topic_id: String!) {
    inviteUserToControllers(email: $email, topic_id: $topic_id)
  }
`;
