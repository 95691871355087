import React, { useEffect, useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Title from "../Title";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { Auth } from "aws-amplify";
import { setPattern, lightsOff } from "../../graphql/mutations";
import { API, graphqlOperation } from "aws-amplify";
import SavePatternModal from "../Saved Patterns/SavePatternModal";
import CreateTimerModal from "../Timers/CreateTimerModal";
import { Context as LightingContext } from "../../context/LightingContext";
import { Context as ControllerGroupContext } from "../../context/ControllerGroupContext";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  buttonContainer: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  currentMqttTopicText: {
    color: "#B5B5B5",
  },
  currentMqttTopicValueText: {
    color: "#92C9F8",
    paddingLeft: 5,
  },
}));

const LightingOptions = ({ numberOfLightsSet, ledColors }) => {
  const classes = useStyles();
  const [age, setAge] = useState("");
  const { fetchMqttTopics, setCurrentMqttTopic } = useContext(
    ControllerGroupContext
  );
  const controllerGroupState = useContext(ControllerGroupContext).state;
  const { currentMqttTopic, mqttTopics } = controllerGroupState;

  const {
    setLightingMode,
    setNumberOfLights,
    setSpeedOfPattern,
    setGapBetweenPattern,
    setPatternPause,
    setPatternDirection,
    setMqttTopic,
    state,
    setErrorMessage,
    setSuccessMessage,
  } = useContext(LightingContext);

  const {
    lightingMode,
    numberOfLights,
    speedOfPattern,
    gapBetweenPattern,
    patternPause,
    patternDirection,
    mqttTopic,
  } = state;

  const setPatternForCurrentTopic = async () => {
    try {
      await API.graphql(
        graphqlOperation(setPattern, {
          mqtt_topic: currentMqttTopic.id,
          pattern: {
            colors: ledColors,
            direction: patternDirection,
            gap: gapBetweenPattern,
            pause: patternPause,
            speed: speedOfPattern,
            type: lightingMode,
          },
        })
      );
      setSuccessMessage({ message: "Pattern Set" });
    } catch (error) {
      setErrorMessage({ message: "Failed to Set Pattern" });
    }
  };

  const turnLightsOff = async () => {
    try {
      await API.graphql(
        graphqlOperation(lightsOff, {
          mqtt_topic: currentMqttTopic.id,
        })
      );
      setSuccessMessage({ message: "Lights Off" });
    } catch (error) {
      setErrorMessage({ message: "Failed to Turn Lights Off" });
    }
  };

  const getGraphqlInfo = async () => {
    fetchMqttTopics();
  };

  useEffect(() => {
    if ((currentMqttTopic.id === undefined, mqttTopics?.length > 0)) {
      setCurrentMqttTopic({ newMqttTopic: mqttTopics[0] });
    }
  }, [mqttTopics]);

  useEffect(() => {
    getGraphqlInfo();
  }, []);

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const handleLightingModeChange = (event) => {
    setLightingMode({ lightingMode: event.target.value });
  };

  const handleNumberOfLightsChange = (event) => {
    setNumberOfLights({ numberOfLights: event.target.value });
    numberOfLightsSet(event.target.value);
  };

  const handleSpeedOfPatternChange = (event) => {
    setSpeedOfPattern({ speedOfPattern: event.target.value });
  };

  const handleGapBetweenPatternChange = (event) => {
    setGapBetweenPattern({ gapBetweenPattern: event.target.value });
  };

  const handlePatternPause = (event) => {
    setPatternPause({ patternPause: event.target.value });
  };

  const handlePatternDirection = (event) => {
    setPatternDirection({ patternDirection: event.target.value });
  };

  const numberOfLightsSelectorViews = () => {
    let menuViews = [];
    for (let i = 1; i < 200; i++) {
      menuViews.push(
        <MenuItem value={i} key={i}>
          {i}
        </MenuItem>
      );
    }
    return menuViews;
  };

  const speedOfPatternSelectorViews = () => {
    let menuViews = [];
    for (let i = 1; i <= 20; i++) {
      menuViews.push(
        <MenuItem value={i} key={i}>
          {i}
        </MenuItem>
      );
    }
    return menuViews;
  };

  const gapBetweenPatternSelectorViews = () => {
    let menuViews = [];
    for (let i = 0; i <= 100; i++) {
      menuViews.push(
        <MenuItem value={i} key={i}>
          {i}
        </MenuItem>
      );
    }
    return menuViews;
  };

  const patternPauseSelectorViews = () => {
    let menuViews = [];
    for (let i = 0; i <= 20; i++) {
      menuViews.push(
        <MenuItem value={i} key={i}>
          {i}
        </MenuItem>
      );
    }
    return menuViews;
  };

  const NumberOfLightsSelector = () => {
    if (lightingMode) {
      return (
        <FormControl className={classes.formControl}>
          <InputLabel
            id="demo-simple-select-helper-label"
            style={{ color: "white" }}
          >
            Number of Lights
          </InputLabel>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={numberOfLights}
            onChange={handleNumberOfLightsChange}
            style={{ color: "white" }}
          >
            {numberOfLightsSelectorViews()}
          </Select>
        </FormControl>
      );
    } else {
      return null;
    }
  };

  const SpeedOfPatternSelector = () => {
    if (lightingMode !== "Split" && lightingMode !== "Custom") {
      return (
        <FormControl className={classes.formControl}>
          <InputLabel
            id="demo-simple-select-helper-label"
            style={{ color: "white" }}
          >
            Speed of Pattern
          </InputLabel>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={speedOfPattern}
            onChange={handleSpeedOfPatternChange}
            style={{ color: "white" }}
          >
            {speedOfPatternSelectorViews()}
          </Select>
        </FormControl>
      );
    } else {
      return null;
    }
  };

  const GapBetweenPatternSelector = () => {
    if (
      lightingMode === "Streak" ||
      lightingMode === "Twinkle" ||
      lightingMode === "Sprinkle"
    ) {
      return (
        <FormControl className={classes.formControl}>
          <InputLabel
            id="demo-simple-select-helper-label"
            style={{ color: "white" }}
          >
            Gap Between Patterns
          </InputLabel>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={gapBetweenPattern}
            onChange={handleGapBetweenPatternChange}
            style={{ color: "white" }}
          >
            {gapBetweenPatternSelectorViews()}
          </Select>
        </FormControl>
      );
    } else {
      return null;
    }
  };

  const PatternPauseSelector = () => {
    if (lightingMode === "Fade") {
      return (
        <FormControl className={classes.formControl}>
          <InputLabel
            id="demo-simple-select-helper-label"
            style={{ color: "white" }}
          >
            Pause Between Patterns
          </InputLabel>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={patternPause}
            onChange={handlePatternPause}
            style={{ color: "white" }}
          >
            {patternPauseSelectorViews()}
          </Select>
        </FormControl>
      );
    } else {
      return null;
    }
  };

  const PatternDirectionSelector = () => {
    if (
      lightingMode === "Chase" ||
      lightingMode == "March" ||
      lightingMode === "Streak"
    ) {
      return (
        <FormControl className={classes.formControl}>
          <InputLabel
            id="demo-simple-select-helper-label"
            style={{ color: "white" }}
          >
            Pattern Direction
          </InputLabel>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={patternDirection}
            onChange={handlePatternDirection}
            style={{ color: "white" }}
          >
            <MenuItem value={"Forward"}>Forward</MenuItem>
            <MenuItem value={"Backward"}>Backward</MenuItem>
          </Select>
        </FormControl>
      );
    } else {
      return null;
    }
  };

  return (
    <div>
      <Title>Pattern Options</Title>
      <>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
        >
          <Typography
            className={classes.currentMqttTopicText}
            variant="subtitle1"
            gutterBottom
          >
            Current Controller Group:
          </Typography>
          <Typography
            className={classes.currentMqttTopicValueText}
            variant="subtitle1"
            gutterBottom
          >
            {currentMqttTopic.name}
          </Typography>
        </Grid>
      </>
      <FormControl className={classes.formControl}>
        <InputLabel
          id="demo-simple-select-helper-label"
          style={{ color: "white" }}
        >
          Lighting Mode
        </InputLabel>
        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={lightingMode}
          onChange={handleLightingModeChange}
          style={{ color: "white" }}
        >
          <MenuItem value="Custom">Custom</MenuItem>
          <MenuItem value="Chase">Chase</MenuItem>
          <MenuItem value="March">March</MenuItem>
          <MenuItem value="Streak">Streak</MenuItem>
          <MenuItem value="Twinkle">Twinkle</MenuItem>
          <MenuItem value="Sprinkle">Sprinkle</MenuItem>
          <MenuItem value="Fade">Fade</MenuItem>
          <MenuItem value="Split">Split</MenuItem>
        </Select>
      </FormControl>
      <NumberOfLightsSelector />
      <SpeedOfPatternSelector />
      <GapBetweenPatternSelector />
      <PatternPauseSelector />
      <PatternDirectionSelector />
      <div className={classes.buttonContainer}>
        <Button
          variant="contained"
          onClick={setPatternForCurrentTopic}
          style={{
            backgroundColor: "#90CAF9",
            color: "#282c34",
            fontWeight: "600",
            marginRight: 15,
            marginTop: 10,
          }}
        >
          Set Pattern
        </Button>
        <SavePatternModal style={{ marginTop: -15 }} />
        <CreateTimerModal />
        <Button
          variant="contained"
          onClick={turnLightsOff}
          style={{
            backgroundColor: "#666666",
            color: "white",
            fontWeight: "600",
            marginTop: 10,
          }}
        >
          Lights Off
        </Button>
      </div>
    </div>
  );
};

export default LightingOptions;
