import React, { useEffect, useState } from "react";
import { withAuthenticator } from "@aws-amplify/ui-react";
import Amplify, { API, graphqlOperation } from "aws-amplify";
import CssBaseline from "@material-ui/core/CssBaseline";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";

import Dashboard from "./components/Lighting Config/Dashboard";

const theme = createMuiTheme({
  palette: {
    background: {
      default: "#282c34",
    },
  },
});

const App = () => {
  return (
    <div>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <Dashboard />
      </MuiThemeProvider>
    </div>
  );
};

export default withAuthenticator(App);
