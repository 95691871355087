import React from "react";
import { SketchPicker } from "react-color";

class ColorPicker extends React.Component {
  constructor(props) {
    super(props);
    this.wrapper = React.createRef();
  }

  state = {
    background: "#fff",
    colors: ["#fff"],
  };

  handleChangeComplete = (color) => {
    const filteredColors = this.state.colors.filter(
      (currentColor) => currentColor !== color.hex
    );

    this.setState({
      background: color.hex,
      colors: [color.hex, ...filteredColors].slice(0, 24),
    });
    this.props.handleChangeComplete(color.hex);
  };

  render() {
    return (
      <SketchPicker
        ref={this.wrapper}
        color={this.state.background}
        onChangeComplete={this.handleChangeComplete}
        disableAlpha={true}
        presetColors={this.state.colors}
      />
    );
  }
}

export default ColorPicker;
