import createDataContext from "./createDataContext";
import { API, graphqlOperation } from "aws-amplify";
import { getUserMqttTopicsV2, getUserControllers } from "../graphql/queries";

const controllerGroupReducer = (state, action) => {
  switch (action.type) {
    case "fetch_mqtt_topics":
      return { ...state, mqttTopics: action.payload };
    case "set_current_mqtt_topic":
      return { ...state, currentMqttTopic: action.payload };
    case "toggle_controller_group_modal":
      return { ...state, createControllerGroupModalOpen: action.payload };
    case "fetch_user_controllers":
      return { ...state, userControllers: action.payload };
    default:
      return state;
  }
};

const fetchMqttTopics = (dispatch) => async () => {
  try {
    const mqttTopics = await API.graphql(graphqlOperation(getUserMqttTopicsV2));
    dispatch({
      type: "fetch_mqtt_topics",
      payload: mqttTopics.data?.getUserMqttTopicsV2?.items ?? [],
    });
  } catch (error) {
    dispatch({
      type: "mqtt_topic_error",
      payload: error,
    });
  }
};

const fetchControllers = (dispatch) => async () => {
  try {
    const controllers = await API.graphql(graphqlOperation(getUserControllers));
    dispatch({
      type: "fetch_user_controllers",
      payload: controllers.data.getUserControllers,
    });
  } catch (error) {
    console.log(error);
  }
};

const setCurrentMqttTopic =
  (dispatch) =>
  ({ newMqttTopic }) => {
    dispatch({
      type: "set_current_mqtt_topic",
      payload: newMqttTopic,
    });
  };

const toggleControllerGroupModal =
  (dispatch) =>
  ({ open }) => {
    dispatch({
      type: "toggle_controller_group_modal",
      payload: open,
    });
  };

export const { Provider, Context } = createDataContext(
  controllerGroupReducer,
  {
    fetchMqttTopics,
    setCurrentMqttTopic,
    toggleControllerGroupModal,
    fetchControllers,
  },
  {
    mqttTopics: [],
    currentMqttTopic: {
      id: undefined,
      name: "",
    },
    createControllerGroupModalOpen: false,
    userControllers: [],
  }
);
