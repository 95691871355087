import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Amplify from "aws-amplify";
import awsconfig from "./aws-exports";
import { Provider as ControllerGroupProvider } from "./context/ControllerGroupContext";
import { Provider as LightingProvider } from "./context/LightingContext";
import { Provider as TimerProvider } from "./context/TimerContext";

Amplify.configure(awsconfig);

ReactDOM.render(
  <React.StrictMode>
    <ControllerGroupProvider>
      <TimerProvider>
        <LightingProvider>
          <App />
        </LightingProvider>
      </TimerProvider>
    </ControllerGroupProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
