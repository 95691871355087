import React, { useContext, useState, useEffect } from "react";
import "date-fns";
import { makeStyles } from "@material-ui/core/styles";
import { API, graphqlOperation } from "aws-amplify";
import { Context as TimerContext } from "../../context/TimerContext";
import { Context as LightingContext } from "../../context/LightingContext";
import { Context as ControllerGroupContext } from "../../context/ControllerGroupContext";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  TimePicker,
} from "@material-ui/pickers";

import { queryPatternsByUsernameIndex } from "../../graphql/queries";
import { createTimer as graphqlCreateTimer } from "../../graphql/mutations";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    flexDirection: "row",
  },
  formContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  textFieldContainer: {
    marginBottom: 15,
  },
  formControlLabel: {
    marginTop: 10,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  repeatContainer: {
    paddingLeft: 10,
  },
  cancelButton: {
    fontWeight: "600",
    marginTop: 15,
    marginRight: 10,
  },
}));

const CreateTimerCard = ({ onManualClose }) => {
  const classes = useStyles();
  const [timerName, setTimerName] = useState(undefined);
  const [startTime, setStartTime] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date());
  const [patterns, setPatterns] = useState([]);
  const [timerPattern, setTimerPattern] = useState(undefined);
  const [timerErrorMessage, setTimerErrorMessage] = useState(undefined);
  const [repeatDays, setRepeatDays] = useState({
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
  });
  const { state, fetchTimers } = useContext(TimerContext);
  const { setErrorMessage, setSuccessMessage } = useContext(LightingContext);
  const { currentMqttTopic } = useContext(ControllerGroupContext).state;
  const { controllers, savedTimers } = state;
  const DAYS = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const getPatterns = async () => {
    const patterns = await API.graphql(
      graphqlOperation(queryPatternsByUsernameIndex)
    );
    setPatterns(patterns.data.queryPatternsByUsernameIndex.items);
  };

  useEffect(() => {
    getPatterns();
  }, []);

  const hours = (date) => {
    return date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
  };

  const minutes = (date) => {
    return date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
  };

  const getRepeatingDays = () => {
    let repeatArray = [];
    if (repeatDays.sunday) {
      repeatArray.push(0);
    }
    if (repeatDays.monday) {
      repeatArray.push(1);
    }
    if (repeatDays.tuesday) {
      repeatArray.push(2);
    }
    if (repeatDays.wednesday) {
      repeatArray.push(3);
    }
    if (repeatDays.thursday) {
      repeatArray.push(4);
    }
    if (repeatDays.friday) {
      repeatArray.push(5);
    }
    if (repeatDays.saturday) {
      repeatArray.push(6);
    }
    return repeatArray.join(",");
  };

  useEffect(() => {
    fetchTimers();
  }, []);

  // const handleDateChange = (date) => {
  //   setSelectedDate(date);
  // };

  const isValidTimerName = (timerName) => {
    const validationArray = savedTimers.filter((timer) => {
      return timer.name === timerName;
    });
    if (validationArray.length === 0) {
      setTimerErrorMessage(undefined);
    } else {
      setTimerErrorMessage("Timer Name Must Be Unique");
    }
    return validationArray.length === 0;
  };

  const sendCreateTimerCall = async () => {
    try {
      await API.graphql(
        graphqlOperation(graphqlCreateTimer, {
          input: {
            mqtt_topic: currentMqttTopic.id,
            start_time: `${hours(startTime)}:${minutes(startTime)}`,
            end_time: `${hours(endTime)}:${minutes(endTime)}`,
            repeats: getRepeatingDays(),
            name: timerName,
            pattern_id: timerPattern.id,
          },
        })
      );
      setSuccessMessage({ message: "Successfully Saved Timer" });
      fetchTimers();
    } catch (error) {
      setErrorMessage({ message: "Failed to Save Timer" });
    }
  };

  return (
    <div className={classes.paper}>
      <h2 id="spring-modal-title">Create a Timer</h2>
      <form className={classes.container} noValidate>
        <div className={classes.textFieldContainer}>
          <TextField
            style={{ marginLeft: 10, minWidth: 195 }}
            id="standard-basic"
            label="New Timer Name"
            value={timerName}
            onChange={(event) => {
              setTimerName(event.target.value);
              isValidTimerName(event.target.value);
            }}
          />
        </div>
        {timerErrorMessage && (
          <p style={{ color: "red", marginLeft: 9 }}>{timerErrorMessage}</p>
        )}
        <div className={classes.textFieldContainer}>
          {/* <TextField
            id="startTime"
            label="Start Time"
            type="time"
            defaultValue={`${hours()}:${minutes()}`}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 300,
            }}
            onChange={(event) => setStartTime(event.target.value)}
          /> */}
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <TimePicker
              style={{ marginLeft: 10, width: 195 }}
              autoOk
              label="Start Time"
              value={startTime}
              onChange={(date) => setStartTime(date)}
            />
          </MuiPickersUtilsProvider>
        </div>
        <div className={classes.textFieldContainer}>
          {/* <TextField
            id="endTime"
            label="End Time"
            type="time"
            defaultValue={`${hours()}:${minutes()}`}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 300,
            }}
            onChange={(event) => setEndTime(event.target.value)}
          /> */}
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <TimePicker
              style={{ marginLeft: 10, width: 195 }}
              autoOk
              label="End Time"
              value={endTime}
              onChange={(date) => setEndTime(date)}
            />
          </MuiPickersUtilsProvider>
        </div>
        <div>
          <FormControl className={classes.formControl}>
            <InputLabel id="override-pattern-label">Pattern To Use</InputLabel>
            <Select
              labelId="override-pattern-select-label"
              id="override-pattern-select"
              value={timerPattern}
              onChange={(event) => setTimerPattern(event.target.value)}
            >
              {patterns.map((pattern) => (
                <MenuItem value={pattern}>{pattern.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div style={{ paddingTop: 15, paddingBottom: 10 }}>
          <InputLabel>Repeat</InputLabel>
        </div>
        <div className={classes.repeatContainer}>
          <FormControlLabel
            control={
              <Checkbox
                checked={repeatDays.monday}
                onChange={() =>
                  setRepeatDays({ ...repeatDays, monday: !repeatDays.monday })
                }
                name={`repeatCheckBox`}
                color="default"
              />
            }
            label="Monday"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={repeatDays.tuesday}
                onChange={() =>
                  setRepeatDays({ ...repeatDays, tuesday: !repeatDays.tuesday })
                }
                name={`repeatCheckBox`}
                color="default"
              />
            }
            label="Tuesday"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={repeatDays.wednesday}
                onChange={() =>
                  setRepeatDays({
                    ...repeatDays,
                    wednesday: !repeatDays.wednesday,
                  })
                }
                name={`repeatCheckBox`}
                color="default"
              />
            }
            label="Wednesday"
          />
        </div>
        <div className={classes.repeatContainer}>
          <FormControlLabel
            control={
              <Checkbox
                checked={repeatDays.thursday}
                onChange={() =>
                  setRepeatDays({
                    ...repeatDays,
                    thursday: !repeatDays.thursday,
                  })
                }
                name={`repeatCheckBox`}
                color="default"
              />
            }
            label="Thursday"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={repeatDays.friday}
                onChange={() =>
                  setRepeatDays({ ...repeatDays, friday: !repeatDays.friday })
                }
                name={`repeatCheckBox`}
                color="default"
              />
            }
            label="Friday"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={repeatDays.saturday}
                onChange={() =>
                  setRepeatDays({
                    ...repeatDays,
                    saturday: !repeatDays.saturday,
                  })
                }
                name={`repeatCheckBox`}
                color="default"
              />
            }
            label="Saturday"
          />
        </div>
        <div className={classes.repeatContainer}>
          <FormControlLabel
            control={
              <Checkbox
                checked={repeatDays.sunday}
                onChange={() =>
                  setRepeatDays({ ...repeatDays, sunday: !repeatDays.sunday })
                }
                name={`repeatCheckBox`}
                color="default"
              />
            }
            label="Sunday"
          />
        </div>
      </form>
      <div>
        <Button
          className={classes.cancelButton}
          variant="contained"
          onClick={() => onManualClose()}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={
            !timerName ||
            !timerPattern ||
            !startTime ||
            !endTime ||
            timerErrorMessage
          }
          onClick={() => {
            sendCreateTimerCall();
            onManualClose();
          }}
          style={{
            backgroundColor:
              !timerName ||
              !timerPattern ||
              !startTime ||
              !endTime ||
              timerErrorMessage
                ? "#D94646"
                : "#90CAF9",
            color: "#282c34",
            fontWeight: "600",
            marginTop: 15,
          }}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default CreateTimerCard;
