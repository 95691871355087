import React, { useState, useEffect, useContext } from "react";
import Grid from "@material-ui/core/Grid";
import "../../css/ledList.css";
import Paper from "@material-ui/core/Paper";
import Title from "../Title";
import { Context as LightingContext } from "../../context/LightingContext";

const LedList = ({
  selectedColor,
  forceReloadNumber,
  numberOfLights,
  ledColors,
}) => {
  const { state, setLedData } = useContext(LightingContext);
  const { ledData } = state;

  const handleClick = (index) => {
    if (ledData[index].selected) {
      return;
    }
    const dataCopy = ledData.map((item) => ({
      color: item.color,
      selected: false,
    }));

    dataCopy[index].selected = true;
    setLedData({ ledData: dataCopy });
  };

  useEffect(() => {
    setLedData({
      ledData: ledData.map((led) => {
        if (led.selected) {
          return { color: selectedColor, selected: led.selected };
        } else {
          return led;
        }
      }),
    });
  }, [forceReloadNumber]);

  useEffect(() => {
    if (ledData.length > numberOfLights) {
      const splitLedData = ledData.slice(0, numberOfLights);
      splitLedData.forEach((led) => {
        led.selected = false;
      });
      splitLedData[splitLedData.length - 1].selected = true;
      setLedData({ ledData: splitLedData });
    } else {
      const difference = numberOfLights - ledData.length;
      let dataCopy = [...ledData];
      for (let i = 0; i < difference; i++) {
        dataCopy.push({ color: "#ffffff", selected: false });
      }
      setLedData({ ledData: dataCopy });
    }
  }, [numberOfLights]);

  useEffect(() => {
    setLedColors();
  }, [ledData]);

  const setLedColors = () => {
    const colors = ledData.map((currentLed) =>
      currentLed.color.replace("#", "")
    );
    ledColors(colors);
  };

  return (
    <div style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
      <Title>Lighting Pattern</Title>
      <Grid container direction="row" justify="flex-start" alignItems="center">
        {ledData.map((color, index) => {
          return (
            <Paper
              style={{
                margin: 5,
                backgroundColor: color.selected ? "#111111" : "#555555",
              }}
              key={index}
            >
              <div onClick={() => handleClick(index)}>
                <div
                  className={
                    color.selected ? "circleDivDark" : "circleDivLight"
                  }
                  style={{
                    height: 30,
                    width: 30,
                    backgroundColor: color.color,
                    margin: 10,
                  }}
                  key={index}
                />
                <p
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: 16,
                    color: "white", //color.selected ? "white" : "black",
                  }}
                >
                  {index + 1}
                </p>
              </div>
            </Paper>
          );
        })}
      </Grid>
    </div>
  );
};

export default LedList;
