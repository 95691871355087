import React, { useEffect, useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { queryPatternsByUsernameIndex } from "../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import { Context as LightingContext } from "../../context/LightingContext";
import { createPatterns, updatePatterns } from "../../graphql/mutations";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    flexDirection: "row",
  },
  saveButton: {
    backgroundColor: "#90CAF9",
    color: "#282c34",
    fontWeight: "600",
    marginTop: 15,
    marginRight: 10,
  },
  cancelButton: {
    fontWeight: "600",
    marginTop: 15,
    marginRight: 10,
  },
  formControlLabel: {
    marginTop: 10,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  errorText: {
    color: "red",
  },
}));

const SavePatternCard = ({ onManualClose, onError }) => {
  const classes = useStyles();

  const [patternName, setPatternName] = useState("");
  const [overridePattern, setOverridePattern] = useState(false);
  const [patternToOverride, setPatternToOverride] = useState(undefined);
  const [patterns, setPatterns] = useState([]);
  const [errorTextMessage, setErrorTextMessage] = useState("");
  const { state, getPatterns, setErrorMessage, setSuccessMessage } = useContext(
    LightingContext
  );
  const {
    ledData,
    lightingMode,
    patternDirection,
    speedOfPattern,
    gapBetweenPattern,
    patternPause,
  } = state;

  const patternNameUnique = () => {
    return (
      patterns.filter((pattern) => {
        return pattern.name === patternName;
      }).length === 0
    );
  };

  const onSavedPatternClicked = async () => {
    setErrorTextMessage("");
    if (!overridePattern && !patternNameUnique()) {
      setErrorTextMessage("New Pattern Name Must Be Unique");
      return;
    }

    if (overridePattern) {
      updatePattern();
      return;
    }

    try {
      await API.graphql(
        graphqlOperation(createPatterns, {
          input: {
            colors: getColors(),
            name: patternName,
            type: lightingMode,
            direction: patternDirection,
            speed: speedOfPattern,
            gap: gapBetweenPattern,
            pause: patternPause,
          },
        })
      );
      getPatterns();
      onManualClose();
      setSuccessMessage({
        message: "Successfully Saved Pattern",
      });
    } catch (error) {
      onManualClose();
      setErrorMessage({
        message: "Save Pattern Failed",
      });
    }
  };

  const updatePattern = async () => {
    const overrideId = getOverrideId();
    if (!overrideId) {
      setErrorTextMessage("Override Pattern Failed: Couldn't find pattern ID");
      return;
    }
    try {
      await API.graphql(
        graphqlOperation(updatePatterns, {
          input: {
            id: overrideId,
            colors: getColors(),
            name: patternName,
            type: lightingMode,
            direction: patternDirection,
            speed: speedOfPattern,
            gap: gapBetweenPattern,
            pause: patternPause,
          },
        })
      );
      getPatterns();
      onManualClose();
      setSuccessMessage({
        message: "Successfully Updated Pattern",
      });
    } catch (error) {
      onManualClose();
      setErrorMessage({
        message: "Update Pattern Failed",
      });
    }
  };

  const getOverrideId = () => {
    return (
      patterns.find((pattern) => {
        if (pattern.name === patternToOverride.name) {
          return true;
        }
      }).id ?? null
    );
  };

  const getColors = () => {
    return ledData.map((colorData) => {
      return colorData.color.replace("#", "");
    });
  };

  const getPatternInfo = async () => {
    const patterns = await API.graphql(
      graphqlOperation(queryPatternsByUsernameIndex)
    );
    setPatterns(patterns.data.queryPatternsByUsernameIndex.items);
  };

  const patternNameIsValid = () => {
    if (patternName === "") {
      return false;
    }
    const matchingPatternName = patterns.filter(
      (pattern) => pattern === patternName
    );
    return matchingPatternName.length === 0;
  };

  useEffect(() => {
    if (overridePattern && patternName === "") {
      setPatternName(patternToOverride.name);
    }
  }, [patternToOverride]);

  useEffect(() => {
    getPatternInfo();
  }, []);

  return (
    <div className={classes.paper}>
      <h2 id="spring-modal-title">Save Pattern</h2>
      <TextField
        id="standard-basic"
        label="New Pattern Name"
        value={patternName}
        onChange={(event) => {
          setPatternName(event.target.value);
          setErrorTextMessage("");
        }}
      />
      <div>
        <FormControlLabel
          className={classes.formControlLabel}
          control={
            <Checkbox
              className={classes.checkbox}
              checked={overridePattern}
              onChange={() => {
                if (!overridePattern) {
                  setPatternToOverride(undefined);
                }
                setOverridePattern(!overridePattern);
              }}
              color="primary"
            />
          }
          label="Override a Current Pattern"
        />
      </div>
      {overridePattern && patterns.length > 0 && (
        <div>
          <FormControl className={classes.formControl}>
            <InputLabel id="override-pattern-label">
              Pattern to Override
            </InputLabel>
            <Select
              labelId="override-pattern-select-label"
              id="override-pattern-select"
              value={patternToOverride}
              onChange={(event) => setPatternToOverride(event.target.value)}
            >
              {patterns.map((pattern) => (
                <MenuItem value={pattern}>{pattern.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      )}
      {overridePattern && patterns.length === 0 && (
        <p style={{ marginLeft: 10, color: "#D94646" }}>
          No Saved Patterns to Override...
        </p>
      )}
      <div>
        <Button
          className={classes.cancelButton}
          variant="contained"
          onClick={() => onManualClose()}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={
            (overridePattern && patterns.length === 0) ||
            (!overridePattern && !patternNameIsValid()) ||
            (overridePattern && !patternToOverride)
          }
          onClick={() => onSavedPatternClicked(patternName, overridePattern)}
          style={{
            backgroundColor:
              (overridePattern && patterns.length === 0) ||
              (!overridePattern && !patternNameIsValid()) ||
              (overridePattern && !patternToOverride)
                ? "#D94646"
                : "#90CAF9",
            color: "#282c34",
            fontWeight: "600",
            marginTop: 15,
          }}
        >
          Save
        </Button>
      </div>
      <div>
        <p className={classes.errorText}>{errorTextMessage}</p>
      </div>
    </div>
  );
};

export default SavePatternCard;
