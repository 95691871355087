/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAllUserTimers = /* GraphQL */ `
  query GetAllUserTimers {
    getAllUserTimers {
      timers {
        active
        end_time
        id
        mqtt_topic
        name
        repeats
        start_time
        pattern {
          colors
          direction
          gap
          id
          name
          pause
          speed
          type
          username
        }
      }
    }
  }
`;
export const getAllUserCalendars = /* GraphQL */ `
  query GetAllUserCalendars {
    getAllUserCalendars {
      calendars {
        active
        end_time
        id
        mqtt_topic
        name
        day
        month
        start_time
      }
    }
  }
`;
export const getControllerMqttTopics = /* GraphQL */ `
  query GetControllerMqttTopics($mqtt_topic: String!, $serial: String!) {
    getControllerMqttTopics(mqtt_topic: $mqtt_topic, serial: $serial) {
      mqtt_topic
      serial
    }
  }
`;
export const getControllerMqttTopicsBySerial = /* GraphQL */ `
  query GetControllerMqttTopicsBySerial($serial: String!) {
    getControllerMqttTopicsBySerial(serial: $serial) {
      items {
        mqtt_topic
        serial
      }
      nextToken
    }
  }
`;
export const getControllerTimers = /* GraphQL */ `
  query GetControllerTimers($mqtt_topics: [String!]) {
    getControllerTimers(mqtt_topics: $mqtt_topics) {
      timers {
        active
        end_time
        id
        repeats
        start_time
        day
        month
      }
    }
  }
`;
export const getControllers = /* GraphQL */ `
  query GetControllers($serial: String!) {
    getControllers(serial: $serial) {
      light_count
      main_topic
      nickname
      rgb_order
      serial
      version
    }
  }
`;
export const getPatterns = /* GraphQL */ `
  query GetPatterns($id: String!) {
    getPatterns(id: $id) {
      colors
      direction
      gap
      id
      name
      pause
      speed
      type
      username
    }
  }
`;
export const getPatternsByUsername = /* GraphQL */ `
  query GetPatternsByUsername($id: String!) {
    getPatternsByUsername(id: $id) {
      colors
      direction
      gap
      id
      name
      pause
      speed
      type
      username
    }
  }
`;
export const getSingleUserTimer = /* GraphQL */ `
  query GetSingleUserTimer($timer_id: String!) {
    getSingleUserTimer(timer_id: $timer_id) {
      active
      end_time
      id
      mqtt_topic
      name
      pattern {
        colors
        direction
        gap
        id
        name
        pause
        speed
        type
        username
      }
      repeats
      start_time
    }
  }
`;
export const getSingleUserCalendar = /* GraphQL */ `
  query GetSingleUserCalendar($calendar_id: String!) {
    getSingleUserCalendar(calendar_id: $calendar_id) {
      active
      end_time
      id
      mqtt_topic
      name
      pattern {
        colors
        direction
        gap
        id
        name
        pause
        speed
        type
        username
      }
      day
      month
      start_time
    }
  }
`;
export const getUserControllers = /* GraphQL */ `
  query GetUserControllers {
    getUserControllers {
      light_count
      main_topic
      nickname
      rgb_order
      serial
      version
    }
  }
`;
export const getUserMqttTopics = /* GraphQL */ `
  query GetUserMqttTopics {
    getUserMqttTopics
  }
`;
export const getUserMqttTopicsV2 = /* GraphQL */ `
  query GetUserMqttTopicsV2 {
    getUserMqttTopicsV2 {
      items {
        id
        username
        controllers
        name
      }
    }
  }
`;
export const listControllerMqttTopics = /* GraphQL */ `
  query ListControllerMqttTopics(
    $filter: TableControllerMqttTopicsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listControllerMqttTopics(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        mqtt_topic
        serial
      }
      nextToken
    }
  }
`;
export const listControllers = /* GraphQL */ `
  query ListControllers(
    $filter: TableControllersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listControllers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        light_count
        main_topic
        nickname
        rgb_order
        serial
        version
      }
      nextToken
    }
  }
`;
export const listPatterns = /* GraphQL */ `
  query ListPatterns(
    $filter: TablePatternsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPatterns(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        colors
        direction
        gap
        id
        name
        pause
        speed
        type
        username
      }
      nextToken
    }
  }
`;
export const listUserControllers = /* GraphQL */ `
  query ListUserControllers(
    $filter: TableUserControllersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserControllers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        controller_serial
        username
      }
      nextToken
    }
  }
`;
export const listUserMqttTopics = /* GraphQL */ `
  query ListUserMqttTopics(
    $filter: TableUserMqttTopicsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserMqttTopics(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        mqtt_topic
        username
      }
      nextToken
    }
  }
`;
export const queryControllerMqttTopicsByMqttTopicIndex = /* GraphQL */ `
  query QueryControllerMqttTopicsByMqttTopicIndex(
    $after: String
    $first: Int
    $mqtt_topic: String!
  ) {
    queryControllerMqttTopicsByMqttTopicIndex(
      after: $after
      first: $first
      mqtt_topic: $mqtt_topic
    ) {
      items {
        mqtt_topic
        serial
      }
      nextToken
    }
  }
`;
export const queryPatternsByUsernameIndex = /* GraphQL */ `
  query QueryPatternsByUsernameIndex($after: String, $first: Int) {
    queryPatternsByUsernameIndex(after: $after, first: $first) {
      items {
        colors
        direction
        gap
        id
        name
        pause
        speed
        type
        username
      }
      nextToken
    }
  }
`;
export const queryUserMqttTopicsByUsername = /* GraphQL */ `
  query QueryUserMqttTopicsByUsername($limit: Int) {
    queryUserMqttTopicsByUsername(limit: $limit) {
      items {
        mqtt_topic
        username
      }
      nextToken
    }
  }
`;
export const getLatestControllerVersion = /* GraphQL */ `
  query GetLatestControllerVersion($model: String!) {
    getLatestControllerVersion(model: $model) {
      model
      version
    }
  }
`;
