import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { useSpring, animated } from "react-spring/web.cjs";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import ControllerGroupCard from "./ControllerGroupsCard";
import { Context as ControllerGroupContext } from "../../context/ControllerGroupContext";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const Fade = React.forwardRef(function Fade(props, ref) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

Fade.propTypes = {
  children: PropTypes.element,
  in: PropTypes.bool.isRequired,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
};

export default function ControllerGroupsModal() {
  const classes = useStyles();
  const { state, toggleControllerGroupModal } = useContext(
    ControllerGroupContext
  );
  const { createControllerGroupModalOpen } = state;

  const handleOpen = () => {
    toggleControllerGroupModal({ open: true });
  };

  const handleClose = () => {
    toggleControllerGroupModal({ open: false });
  };

  return (
    <>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        className={classes.modal}
        open={createControllerGroupModalOpen}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={createControllerGroupModalOpen}>
          <ControllerGroupCard
            onManualClose={() => toggleControllerGroupModal({ open: false })}
          />
        </Fade>
      </Modal>
    </>
  );
}
