import React, { useState, useEffect, useContext, useRef } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Link from "@material-ui/core/Link";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { mainListItems } from "./listItems";
import Chart from "../Chart";
import Deposits from "../Deposits";
import Orders from "../Orders";
import ColorPicker from "./ColorPicker";
import LedList from "./LedList";
import LightingOptions from "./LightingOptions";
import Button from "@material-ui/core/Button";
import { Auth } from "aws-amplify";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import OeloLogo from "../../assets/OeloLogo.png";
import ExpandableList from "../../components/Saved Patterns/ExpandableList";
import ExpandableTimersList from "../../components/Timers/ExpandableTimersList";
import ExpandableControllerGroupsList from "../../components/Controller Groups/ExpandableControllerGroupsList";
import ControllerGroupsModal from "../../components/Controller Groups/ControllerGroupsModal";
import { Context as TimerContext } from "../../context/TimerContext";
import { Context as LightingContext } from "../../context/LightingContext";
import Alert from "@material-ui/lab/Alert";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        Lonely Man Studios
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

// const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    backgroundColor: "#282c34",
  },
  toolbar: {
    // backgroundColor: "#90CAF9",
    backgroundColor: "#424242",
    color: "#ffffff",
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    // marginLeft: drawerWidth,
    // width: `calc(100% - ${drawerWidth}px)`,
    width: "100%",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    // position: "relative",
    // whiteSpace: "nowrap",
    // width: drawerWidth,
    // transition: theme.transitions.create("width", {
    //   easing: theme.transitions.easing.sharp,
    //   duration: theme.transitions.duration.enteringScreen,
    // }),
    // backgroundColor: "#424242",
    // color: "white",
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    alignItems: "center",
  },
  fixedHeight: {
    height: 340,
  },
  appBarButtonsContainer: {
    flexDirection: "row",
  },
  alertPlaceholder: {
    width: "100%",
    height: 25,
  },
  alertContainer: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function Dashboard() {
  const classes = useStyles();
  const [username, setUsername] = useState("");
  const [open, setOpen] = useState(true);
  const [background, setBackground] = useState("#fff");
  const [forceReloadNumber, setForceReloadNumber] = useState(0);
  const [ledColors, setLedColors] = useState([]);
  const [profileMenuOpen, setProfileMenuOpen] = useState(false);
  const { state, setNumberOfLights } = useContext(LightingContext);
  const { getUserControllers, fetchTimers } = useContext(TimerContext);
  const timerState = useContext(TimerContext).state;
  const { numberOfLights, errorMessage, successMessage } = state;
  const { timerErrorMessage, timerSuccessMessage } = timerState;
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setProfileMenuOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setProfileMenuOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setProfileMenuOpen(false);
    }
  }

  const logUserOut = async () => {
    await Auth.signOut();
    window.location.reload();
  };

  const prevOpen = React.useRef(profileMenuOpen);
  useEffect(() => {
    if (prevOpen.current === true && profileMenuOpen === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = profileMenuOpen;
  }, [profileMenuOpen]);

  useEffect(() => {
    getUserControllers();
    fetchTimers();
  }, []);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  const handleChangeComplete = (color) => {
    setBackground(color);
    setForceReloadNumber(forceReloadNumber + 1);
  };

  const getUserName = async () => {
    const userInfo = await Auth.currentUserInfo();
    setUsername(userInfo ? userInfo.username : "");
  };

  useEffect(() => {
    getUserName();
  });

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>
          <div className={classes.toolbarIcon}>
            <div style={{ flex: 1, paddingLeft: 10, paddingRight: 10 }}>
              <img
                style={{
                  height: 50,
                }}
                src={OeloLogo}
              />
            </div>
          </div>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          />
          <div>
            <ExpandableControllerGroupsList
              className={classes.appBarButtonsContainer}
            />
            <ControllerGroupsModal />
          </div>
          <div>
            <ExpandableTimersList className={classes.appBarButtonsContainer} />
          </div>
          <div>
            <ExpandableList className={classes.appBarButtonsContainer} />
          </div>
          <div>
            <IconButton
              color="inherit"
              ref={anchorRef}
              aria-controls={profileMenuOpen ? "menu-list-grow" : undefined}
              aria-haspopup="true"
              onClick={handleToggle}
            >
              <Button color="inherit">{username}</Button>
              <AccountCircleIcon />
            </IconButton>
            <Popper
              open={profileMenuOpen}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList
                        style={{ backgroundColor: "#424242" }}
                        autoFocusItem={profileMenuOpen}
                        id="menu-list-grow"
                        onKeyDown={handleListKeyDown}
                      >
                        <MenuItem
                          style={{ fontWeight: "600", color: "white" }}
                          onClick={logUserOut}
                        >
                          Logout
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </div>
        </Toolbar>
      </AppBar>
      {/* <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <div style={{ flex: 1, paddingLeft: 10 }}>
            <img
              style={{
                height: 50,
              }}
              src={OeloLogo}
            />
          </div>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon style={{ color: "white" }} />
          </IconButton>
        </div>
        <Divider />
        <List>{mainListItems}</List>
      </Drawer> */}
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <div className={classes.alertPlaceholder}>
          {errorMessage && (
            <div className={classes.alertContainer}>
              <Alert severity="error">{errorMessage}</Alert>
            </div>
          )}
          {successMessage && (
            <div className={classes.alertContainer}>
              <Alert severity="success">{successMessage}</Alert>
            </div>
          )}
        </div>
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            {/* Chart */}
            <Grid item xs={12} md={8} lg={9}>
              <Paper
                className={fixedHeightPaper}
                style={{
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  flex: 1,
                  flexDirection: "row",
                  backgroundColor: "#424242",
                }}
              >
                <LedList
                  selectedColor={background}
                  forceReloadNumber={forceReloadNumber}
                  numberOfLights={numberOfLights}
                  ledColors={(colors) => {
                    setLedColors(colors);
                  }}
                />
              </Paper>
            </Grid>
            {/* Recent Deposits */}
            <Grid item xs={12} md={4} lg={3}>
              <Paper
                className={fixedHeightPaper}
                style={{
                  backgroundColor: "#424242",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ColorPicker handleChangeComplete={handleChangeComplete} />
              </Paper>
            </Grid>
            {/* Recent Orders */}
            <Grid item xs={12}>
              <Paper
                className={classes.paper}
                style={{ alignItems: "flex-start", backgroundColor: "#424242" }}
              >
                <LightingOptions
                  numberOfLightsSet={(value) => {
                    setNumberOfLights({ numberOfLights: value });
                  }}
                  ledColors={ledColors}
                />
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </main>
    </div>
  );
}
