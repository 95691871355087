import React, { useState, useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Popper from "@material-ui/core/Popper";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import DeleteIcon from "@material-ui/icons/Delete";
import Switch from "@material-ui/core/Switch";
import LinearProgress from "@material-ui/core/LinearProgress";
import { API, graphqlOperation } from "aws-amplify";
import { deleteTimer, setTimerActive } from "../../graphql/mutations";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { ListSubheader, Typography } from "@material-ui/core";
import { Context as TimerContext } from "../../context/TimerContext";
import { Context as LightingContext } from "../../context/LightingContext";
import { Context as ControllerGroupContext } from "../../context/ControllerGroupContext";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    minWidth: 360,
    backgroundColor: "#282c34",
    overflow: "auto",
    position: "relative",
  },
  nested: {
    padding: theme.spacing(4),
  },
  toolbar: {
    paddingRight: 24,
    backgroundColor: "#90CAF9",
    color: "#282c34",
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    dispaly: "flex",
    overflow: "auto",
    flexDirection: "column",
    alignItems: "center",
  },
  fixedHeight: {
    height: 340,
  },
  listItem: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  listSubheader: {
    color: "#FFFFFF",
    background: "#666666",
    fontWeight: "Bold",
  },
  listItemText: {
    color: "#ffffff",
    paddingRight: 20,
  },
  inline: {
    display: "inline",
  },
  divider: {
    backgroundColor: "#666666",
    marginLeft: 17,
  },
  linearProgressContainer: {
    backgroundColor: "#444444",
    height: 3,
  },
}));

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#90CAF9",
    },
    secondary: {
      main: "#ffffff",
    },
    error: {
      main: "#cccccc",
    },
  },
});

const ExpandableTimersList = () => {
  const classes = useStyles();
  const anchorRef = React.useRef(null);
  const [savedTimersOpen, setSavedTimersOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { state, fetchTimers } = useContext(TimerContext);
  const { currentMqttTopic } = useContext(ControllerGroupContext).state;
  const { setErrorMessage, setSuccessMessage } = useContext(LightingContext);
  const { savedTimers } = state;
  const prevOpen = React.useRef(savedTimersOpen);

  const handleToggle = () => {
    setSavedTimersOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setSavedTimersOpen(false);
  };

  useEffect(() => {
    if (prevOpen.current === true && savedTimersOpen === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = savedTimersOpen;
  }, [savedTimersOpen]);

  useEffect(() => {
    setLoading(false);
  }, [savedTimers]);

  useEffect(() => {
    if (savedTimersOpen) {
      setLoading(true);
      fetchTimers();
    }
  }, [savedTimersOpen]);

  const convertToStandardTime = (time) => {
    let splitTime = time.split(":");
    splitTime[0] = parseFloat(splitTime[0]);
    const amPm = splitTime[0] < 12 ? "am" : "pm";

    if (splitTime[0] === 0) {
      splitTime[0] = 12;
    } else if (splitTime[0] > 12) {
      splitTime[0] = splitTime[0] - 12;
    }

    return `${splitTime[0]}:${splitTime[1]}${amPm}`;
  };

  const getRepeatString = (repeats) => {
    let repeatArray = repeats.split(",");
    if (repeatArray.length === 0 || repeats === "") {
      return "Never";
    }
    if (repeatArray.length === 7) {
      return "Everyday";
    }

    repeatArray = repeatArray.map((repeatDay) => {
      switch (repeatDay) {
        case "0":
          return "Sunday";
        case "1":
          return "Monday";
        case "2":
          return "Tuesday";
        case "3":
          return "Wednesday";
        case "4":
          return "Thursday";
        case "5":
          return "Friday";
        case "6":
          return "Saturday";
        default:
          return "";
      }
    });

    return repeatArray.join(" | ");
  };

  const callDeleteTimer = async (timer) => {
    setLoading(true);
    try {
      await API.graphql(
        graphqlOperation(deleteTimer, {
          id: timer.id,
        })
      );
      fetchTimers();
    } catch (error) {
      setErrorMessage({ message: "Failed to Delete Timer" });
    }
  };

  const toggleActive = async ({ id, newActiveState }) => {
    setLoading(true);
    try {
      await API.graphql(
        graphqlOperation(setTimerActive, {
          active: newActiveState,
          id,
        })
      );
      fetchTimers();
    } catch (error) {
      setErrorMessage({ message: "Failed to set the timer's active state" });
    }
  };

  return (
    <div>
      <IconButton
        color="inherit"
        ref={anchorRef}
        aria-controls={savedTimersOpen ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Button color="inherit">Saved Timers</Button>
      </IconButton>
      <Popper
        open={savedTimersOpen}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <div className={classes.root}>
                  <div className={classes.linearProgressContainer}>
                    {loading && <LinearProgress />}
                  </div>
                  <List
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    subheader={
                      <ListSubheader
                        className={classes.listSubheader}
                        component="div"
                        id="nested-list-subheader"
                      >
                        {`Saved Timers for: ${currentMqttTopic.name}`}
                      </ListSubheader>
                    }
                    className={classes.root}
                  >
                    {savedTimers.map((timer) => {
                      if (timer.mqtt_topic !== currentMqttTopic.id) {
                        return null;
                      } else {
                        return (
                          <>
                            <ListItem
                              className={classes.listItem}
                              button
                              onClick={() => console.log("")}
                            >
                              <ListItemText
                                className={classes.listItemText}
                                primary={timer.name}
                                secondary={
                                  <React.Fragment>
                                    <MuiThemeProvider theme={theme}>
                                      <div>
                                        <Typography
                                          component="span"
                                          variant="body1"
                                          className={classes.inline}
                                          color="error"
                                        >
                                          {"Time Span: "}
                                        </Typography>
                                        <Typography
                                          component="span"
                                          variant="subtitle1"
                                          className={classes.inline}
                                          color="primary"
                                        >
                                          {`${convertToStandardTime(
                                            timer["start_time"]
                                          )} - ${convertToStandardTime(
                                            timer["end_time"]
                                          )}`}
                                        </Typography>
                                      </div>
                                      <div>
                                        <Typography
                                          component="span"
                                          variant="body1"
                                          className={classes.inline}
                                          color="error"
                                        >
                                          {"Repeats: "}
                                        </Typography>
                                        <Typography
                                          component="span"
                                          variant="body2"
                                          className={classes.inline}
                                          color="primary"
                                        >
                                          {getRepeatString(timer.repeats)}
                                        </Typography>
                                      </div>
                                      <div>
                                        <Typography
                                          component="span"
                                          variant="body1"
                                          className={classes.inline}
                                          color="error"
                                        >
                                          {"Pattern: "}
                                        </Typography>
                                        <Typography
                                          component="span"
                                          variant="body2"
                                          className={classes.inline}
                                          color="primary"
                                        >
                                          {timer.pattern?.name}
                                        </Typography>
                                      </div>
                                    </MuiThemeProvider>
                                  </React.Fragment>
                                }
                              />
                              <ListItemSecondaryAction>
                                <div>
                                  <Switch
                                    edge="end"
                                    onChange={() =>
                                      toggleActive({
                                        newActiveState: timer.active ? 0 : 1,
                                        id: timer.id,
                                      })
                                    }
                                    checked={timer.active === 1 ? true : false}
                                    color="secondary"
                                  />
                                </div>
                                <div>
                                  <IconButton
                                    edge="end"
                                    aria-label="delete"
                                    style={{ color: "#ffffff" }}
                                    onClick={() => {
                                      callDeleteTimer(timer);
                                    }}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </div>
                              </ListItemSecondaryAction>
                            </ListItem>
                            <Divider className={classes.divider} light />
                          </>
                        );
                      }
                    })}
                  </List>
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default ExpandableTimersList;
