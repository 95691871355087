import React, { useEffect, useState, useContext } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Popper from "@material-ui/core/Popper";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import LinearProgress from "@material-ui/core/LinearProgress";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Divider from "@material-ui/core/Divider";
import AddIcon from "@material-ui/icons/Add";
import DoneIcon from "@material-ui/icons/Done";
import DeleteIcon from "@material-ui/icons/Delete";
import { deleteUserMqttTopics } from "../../graphql/mutations";
import { Context as ControllerGroupContext } from "../../context/ControllerGroupContext";
import { Context as LightingContext } from "../../context/LightingContext";
import {
  ClickAwayListener,
  ListSubheader,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    minWidth: 360,
    backgroundColor: "#282c34",
    overflow: "auto",
    position: "relative",
  },
  nested: {
    padding: theme.spacing(4),
  },
  toolbar: {
    paddingRight: 24,
    backgroundColor: "#90CAF9",
    color: "#282c34",
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    dispaly: "flex",
    overflow: "auto",
    flexDirection: "column",
    alignItems: "center",
  },
  fixedHeight: {
    height: 340,
  },
  listItem: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  listSubheader: {
    color: "#FFFFFF",
    background: "#666666",
    fontWeight: "Bold",
  },
  listItemText: {
    color: "#ffffff",
    paddingRight: 20,
  },
  createControllerGroupButtonIcon: {
    color: "#27FF8C",
  },
  createControllerGroupButtonText: {
    color: "#27FF8C",
    paddingRight: 20,
    marginLeft: -20,
  },
  inline: {
    display: "inline",
  },
  divider: {
    backgroundColor: "#666666",
    marginLeft: 17,
  },
  linearProgressContainer: {
    backgroundColor: "#444444",
    height: 3,
  },
  checkmark: {
    color: "#B5B5B5",
  },
}));

const ExpandableControllerGroupsList = () => {
  const classes = useStyles();
  const anchorRef = React.useRef(null);
  const [savedControllerGroupsOpen, setSavedControllerGroupsOpen] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const {
    state,
    fetchMqttTopics,
    setCurrentMqttTopic,
    toggleControllerGroupModal,
  } = useContext(ControllerGroupContext);
  const { setErrorMessage } = useContext(LightingContext);
  const { mqttTopics, currentMqttTopic, createControllerGroupModalOpen } =
    state;
  const prevOpen = React.useRef(savedControllerGroupsOpen);

  const handleToggle = () => {
    setSavedControllerGroupsOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setSavedControllerGroupsOpen(false);
  };

  useEffect(() => {
    if (prevOpen.current === true && savedControllerGroupsOpen === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = savedControllerGroupsOpen;
  }, [savedControllerGroupsOpen]);

  useEffect(() => {
    if (savedControllerGroupsOpen) {
      setLoading(true);
      fetchMqttTopics();
    }
  }, [savedControllerGroupsOpen]);

  useEffect(() => {
    setLoading(false);
  }, [mqttTopics]);

  const setNewMqttTopic = (newTopic) => {
    setCurrentMqttTopic({ newMqttTopic: newTopic });
  };

  const getSecondaryAction = (mqttTopic) => {
    if (currentMqttTopic.name === mqttTopic) {
      return (
        <ListItemSecondaryAction>
          <DoneIcon className={classes.checkmark} />
        </ListItemSecondaryAction>
      );
    } else if (mqttTopic === "ALL") {
      return null;
    } else {
      return (
        <ListItemSecondaryAction>
          <IconButton
            edge="end"
            aria-label="delete"
            style={{ color: "#ffffff" }}
            onClick={() => {
              deleteTopic(mqttTopic);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </ListItemSecondaryAction>
      );
    }
  };

  const deleteTopic = async (mqttTopic) => {
    try {
      setLoading(true);
      const response = await API.graphql(
        graphqlOperation(deleteUserMqttTopics, {
          mqtt_topic: mqttTopic.id,
        })
      );
      fetchMqttTopics();
    } catch (error) {
      setLoading(false);
      setErrorMessage({ message: "Failed to Delete Controller Group" });
    }
  };

  return (
    <div>
      <IconButton
        color="inherit"
        ref={anchorRef}
        aria-controls={savedControllerGroupsOpen ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Button color="inherit">{`Controller Groups`}</Button>
      </IconButton>
      <Popper
        open={savedControllerGroupsOpen}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <div className={classes.root}>
                  <div className={classes.linearProgressContainer}>
                    {loading && <LinearProgress />}
                  </div>
                  <List
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    subheader={
                      <ListSubheader
                        className={classes.listSubheader}
                        component="div"
                        id="nested-list-subheader"
                      >
                        Controller Groups
                      </ListSubheader>
                    }
                    className={classes.root}
                  >
                    <>
                      <ListItem
                        className={classes.ListItem}
                        button
                        onClick={() => {
                          toggleControllerGroupModal({
                            open: !createControllerGroupModalOpen,
                          });
                          handleToggle();
                        }}
                      >
                        <ListItemIcon
                          className={classes.createControllerGroupButtonIcon}
                        >
                          <AddIcon />
                        </ListItemIcon>
                        <ListItemText
                          className={classes.createControllerGroupButtonText}
                          primary="Create a New Controller Group"
                        />
                      </ListItem>
                      <Divider className={classes.divider} light />
                    </>
                    {mqttTopics.map((mqttTopic) => (
                      <>
                        <ListItem
                          className={classes.ListItem}
                          button
                          onClick={() => setNewMqttTopic(mqttTopic)}
                          key={mqttTopic.name}
                        >
                          <ListItemText
                            className={classes.listItemText}
                            primary={mqttTopic.name}
                          />
                          {getSecondaryAction(mqttTopic)}
                        </ListItem>
                        <Divider className={classes.divider} light />
                      </>
                    ))}
                  </List>
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default ExpandableControllerGroupsList;
