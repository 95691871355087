import React, { useEffect, useState, useContext } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import LinearProgress from "@material-ui/core/LinearProgress";
import { createUserMqttTopics } from "../../graphql/mutations";
import { Context as ControllerGroupContext } from "../../context/ControllerGroupContext";
import { Context as LightingContext } from "../../context/LightingContext";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    flexDirection: "row",
    maxWidth: 400,
  },
  saveButton: {
    backgroundColor: "#90CAF9",
    color: "#282c34",
    fontWeight: "600",
    marginTop: 15,
    marginRight: 10,
  },
  cancelButton: {
    fontWeight: "600",
    marginTop: 15,
    marginRight: 10,
  },
  formControlLabel: {
    marginTop: 10,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  errorText: {
    color: "red",
  },
  textField: {
    marginBottom: 10,
  },
  divider: {
    backgroundColor: "#666666",
    marginLeft: 17,
  },
  linearProgressContainer: {
    height: 3,
  },
  errorMessage: {
    color: "#F65047",
  },
}));

const ControllerGroupCard = ({ onManualClose }) => {
  const classes = useStyles();
  const [controllerGroupName, setControllerGroupName] = useState("");
  const [checkboxState, setCheckboxState] = useState([]);
  const [loading, setLoading] = useState(false);
  const [controllerGroupError, setControllerGroupError] = useState(undefined);
  const { setErrorMessage, setSuccessMessage } = useContext(LightingContext);
  const { state, fetchControllers, toggleControllerGroupModal } = useContext(
    ControllerGroupContext
  );
  const { createControllerGroupModalOpen, userControllers } = state;

  useEffect(() => {
    if (createControllerGroupModalOpen) {
      setLoading(true);
      fetchControllers();
    }
  }, [createControllerGroupModalOpen]);

  useEffect(() => {
    if (userControllers.length > 0) {
      setLoading(false);
    }
    if (checkboxState.length === 0) {
      let initializationArray = userControllers.map(() => false);
      setCheckboxState(initializationArray);
    }
  }, [userControllers]);

  const isValidControllerGroupName = (controllerGroupName) => {};

  const hasSelectedController = () => {
    return checkboxState.includes(true);
  };

  const saveControllerGroup = async () => {
    let selectedControllerIndexes = [];
    checkboxState.forEach((checkboxState, index) => {
      if (checkboxState) {
        selectedControllerIndexes.push(index);
      }
    });

    const selectedControllers = selectedControllerIndexes.map(
      (controllerIndex) => userControllers[controllerIndex].serial
    );

    try {
      await API.graphql(
        graphqlOperation(createUserMqttTopics, {
          input: {
            mqtt_topic: controllerGroupName,
            controllers: selectedControllers,
          },
        })
      );
      setSuccessMessage({ message: "Successfully Created Controller Group" });
      toggleControllerGroupModal(!createControllerGroupModalOpen);
    } catch (error) {
      setErrorMessage({ message: "Failed to Create Controller Group" });
      setControllerGroupError(
        error.errors[0]?.message ?? "Unknown error occurred"
      );
    }
  };

  return (
    <div className={classes.paper}>
      <h2 id="spring-modaltitle">Create Controller Group</h2>
      <form>
        <div>
          <TextField
            className={classes.textField}
            style={{ marginLeft: 10, minWidth: 220 }}
            id="standerd-basic"
            label="New Controller Group Name"
            value={controllerGroupName}
            onChange={(event) => {
              setControllerGroupError(undefined);
              setControllerGroupName(event.target.value);
              isValidControllerGroupName(event.target.value);
            }}
          />
        </div>
        <h3>Controllers to Add</h3>
        <div className={classes.linearProgressContainer}>
          {loading && <LinearProgress />}
        </div>
        {userControllers.length > 0 &&
          userControllers.map((controller, index) => {
            return (
              <FormControlLabel
                control={
                  <Checkbox
                    // checked={checkboxState[index]}
                    onChange={() => {
                      let arrayCopy = [...checkboxState];
                      arrayCopy[index] = !arrayCopy[index];
                      setCheckboxState(arrayCopy);
                    }}
                    name={`controllerCheckBox`}
                    color="default"
                  />
                }
                label={controller.nickname}
              />
            );
          })}
        <div>
          {controllerGroupError && (
            <p className={classes.errorMessage}>{controllerGroupError}</p>
          )}
        </div>
        <div>
          <Button
            className={classes.cancelButton}
            variant="contained"
            onClick={() => onManualClose()}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            disabled={!hasSelectedController() || controllerGroupName === ""}
            onClick={() => saveControllerGroup()}
            style={{
              backgroundColor:
                !hasSelectedController() || controllerGroupName === ""
                  ? "#D94646"
                  : "#90CAF9",
              color: "#282c34",
              fontWeight: "600",
              marginTop: 15,
            }}
          >
            Save
          </Button>
        </div>
      </form>
    </div>
  );
};

export default ControllerGroupCard;
